import { Header, Splash, Services, AboutZadro, BITD, Projects, Footer, ContactBlock } from '../components/index.js'
import Head from 'next/head'
import { NextSeo } from 'next-seo';


export default function Home() {
  return (
    <div className="font-['Montserrat']">
      <NextSeo
        title="Your Tech Partner at reach Zadro | We're geeks at heart."
        description="To Meet Your Business Needs, Zadro provide the best end-to-end Web and mobile application development services around the world."
        noindex={false}
      />
      <div className="relative overflow-hidden">
        <main>
          {/* <Header /> */}
          <Splash />
        </main>
      </div>
      <Services />
      <AboutZadro />
      <Projects />
      <BITD />
      {/* <FAQ /> */}
      <ContactBlock />
      <Footer />
    </div>
  )
}
export async function getServerSideProps({ req, res }) {
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59'
  )

  return {
    props: {},
  }
}
